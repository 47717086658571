.container {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    padding: 50px;
    text-align: center;
}

.submit {
    margin-top:15px;
    width:50%;
    height:50px;
    border: none;
    background-color: #02b2b0 ;
    color:white;
    font-weight: 500;
    font-size: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
